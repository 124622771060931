<template>
  <v-app>
    <div>
      <!-- Filter -->
      <div class="row justify-content-between mt-n3">
        <div class="col-md-5 col-12">
          <button
            class="btn mx-1 btn-timeseries"
            style="
                  padding-left: 4px;
                  padding-right: 4px;
                  padding-top: 5px;
                  padding-bottom: 5px;
                "
            @click="btnActiveOnClick('days')"
            v-bind:class="{
              'btn-info': btnActive.days,
              'btn-outline-info': btnActive.days == false,
            }">
            Hari Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
                  padding-left: 4px;
                  padding-right: 4px;
                  padding-top: 5px;
                  padding-bottom: 5px;
                "
            @click="btnActiveOnClick('month')"
            v-bind:class="{
              'btn-info': btnActive.month,
              'btn-outline-info': btnActive.month == false,
            }">
            Bulan Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
                  padding-left: 4px;
                  padding-right: 4px;
                  padding-top: 5px;
                  padding-bottom: 5px;
                "
            @click="btnActiveOnClick('lastMonth')"
            v-bind:class="{
              'btn-info': btnActive.lastMonth,
              'btn-outline-info': btnActive.lastMonth == false,
            }">
            Bulan Kemarin
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
                  padding-left: 4px;
                  padding-right: 4px;
                  padding-top: 5px;
                  padding-bottom: 5px;
                "
            @click="btnActiveOnClick('year')"
            v-bind:class="{
              'btn-info': btnActive.year,
              'btn-outline-info': btnActive.year == false,
            }">
            Tahun Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
                  padding-left: 4px;
                  padding-right: 4px;
                  padding-top: 5px;
                  padding-bottom: 5px;
                "
            v-bind:class="{
              'btn-info': btnActive.period,
              'btn-outline-info': btnActive.period == false,
            }"
            @click="btnPeriodeOnClick">
            Periode
          </button>
        </div>
        <div class="col-md-3 col-12 mt-md-0 mt-n4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text squared>
                <b-icon-search></b-icon-search>
              </b-input-group-text>
            </template>
            <b-form-input
              type="text"
              v-model="filter.name"
              placeholder="Cari..."
              @keyup="filterByName"></b-form-input>
          </b-input-group>
          <small class="text-muted">*Cari Nama, No atau Orang Tua Pasien</small>
        </div>
        <div class="col-md-4 col-12">
          <b-input-group>
            <v-dialog
              ref="dialog"
              v-model="datePickerPopUp"
              :return-value.sync="filter.birt_date"
              persistent
              width="350px">
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  placeholder="Tanggal Lahir"
                  id="input-trigger-datepicker"
                  v-model="filter.birt_date">
                </b-form-input>
              </template>
              <v-date-picker
                locale="id"
                v-if="datePickerPopUp"
                v-model="filter.birt_date">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="datePickerPopUp = false">Cancel</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(filter.birt_date)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
            <template #append>
              <b-button squared @click="filterByBirtDate" variant="success">Cari</b-button>
              <b-button squared @click="resetFilter" variant="danger">Reset</b-button>
            </template>
          </b-input-group>
        </div>
      </div>

      <!-- timeseries -->
      <div class="row justify-content-start align-items-center mt-3">
        <div class="col-md-2">
          <span
            v-if="recordData != null"
            style="width: 150px; color: #245590 !important"
            class="font-weight-bold">{{ recordData }} Baris Data</span>
        </div>
        <div class="col-md-8">
          <div v-if="onShowPeriod">
            <span class="d-none d-sm-block">
              <b-input-group>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Awal"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Akhir"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
                <template #append>
                  <b-button squared @click="pagination" variant="success">Cari</b-button>
                  <b-button squared @click="resetFilterPeriod" variant="danger">Reset</b-button>
                </template>
              </b-input-group>
            </span>
            <span class="d-block d-sm-none">
              <div class="row">
                <div class="col-12 pb-1">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="col-12 pt-0">
                  <b-input-group>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="pagination" variant="success">Cari Periode</b-button>
                      <b-button
                        squared
                        @click="resetFilterPeriod"
                        variant="danger">Reset Periode</b-button>
                    </template>
                  </b-input-group>
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-2 d-flex justify-content-end">
          <b-button variant="primary" @click="getExcel">
            <i class="fas fa-file-excel pr-1"></i>
            Export
          </b-button>
        </div>
      </div>
      <!-- Table -->
      <b-table
        striped
        hover
        responsive="sm"
        class="mt-3"
        :items="items"
        :fields="fields">
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width:
                field.key === 'actions'
                  ? purpose == 'master'
                    ? '10%'
                    : ''
                  : '',
              width: field.key === 'responsible_doctor' ? '10%' : '',
            }" />
        </template>

        <template #cell(patient_name)="data">
          <span class="d-block">{{ data.item.patient_name }}
            {{ "(" + data.item.patient_id + ")" }}</span>
        </template>

        <template #cell(birt_date)="data">
          <ul :style="'list-style-type: none;'">
            <li>
              {{ data.item.display_birt_date }}
            </li>
            <li>
              {{ data.item.parent_name }}
            </li>
          </ul>
        </template>

        <!-- <template #cell(doctor_in_charge)="data">
          <div
            v-for="(doctorName, index) in data.item.doctor_in_charge_name"
            :key="index"
          >
            <li>
              {{ doctorName }}
            </li>
          </div>
        </template> -->

        <template #cell(doctor)="data">
          <span class="font-weight-bolder">Dokter Yang Menangani :</span>

          <div
            v-for="(doctorName, index) in data.item.doctor_in_charge_name"
            :key="index">
            <li>
              {{ doctorName }}
            </li>
          </div>

          <span class="font-weight-bolder">DPJP :</span>

          <div
            v-for="(doctorName, index) in data.item.responsible_doctor_name"
            :key="index">
            <li>
              {{ doctorName }}
            </li>
          </div>
        </template>

        <!-- <template #cell(responsible_doctor)="data">
          
          <div
            v-for="(doctorName, index) in data.item.responsible_doctor_name"
            :key="index"
          >
            <li>
              {{ doctorName }}
            </li>
          </div>
        </template> -->

        <template #cell(photo)="data">
          <div
            class="d-flex justify-content-center align-items-center image-container">
            <div style="height: 50px; width: 50px; position: relative">
              <img class="image" :src="data.item.photo" />
            </div>
          </div>
        </template>

        <template #cell(actions)="data">
          <template v-if="purpose == 'master'">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-12">
                <div class="d-flex justify-content-center">
                  <b-button v-if="tenantConfig.subscribe_package_id != 4"
                    size="sm"
                    class="mr-1"
                    variant="primary"
                    v-b-tooltip.hover
                    title="Medical Check Up"
                    @click="
                      $router.push({
                        path:
                          '/medical-checkup/list-by-patient/' + data.item.id,
                      })
                    "><i class="fas fa-file-medical px-0"></i>
                  </b-button>

                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({ path: '/patient/detail/' + data.item.id })
                    "><i class="fas fa-eye px-0"></i></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Ubah"
                    v-if="manipulateBtn == true"
                    @click="
                      $router.push({ path: '/patient/edit/' + data.item.id })
                    "><i class="fas fa-edit px-0"></i></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Riwayat"
                    v-if="historyBtn == true && tenantConfig.subscribe_package_id != 4"
                    @click="
                      $router.push({ path: '/patient/history/' + data.item.id })
                    "><i class="fas fa-list-alt px-0"></i></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Pembayaran"
                    v-if="paymentBtn == true && tenantConfig.subscribe_package_id != 4"
                    @click="
                      $router.push({ path: '/patient/payment/' + data.item.id })
                    "><i class="fas fa-money-bill-wave px-0"></i></b-button>

                  <!-- </div>
              </div>
              <div class="col-md-12 col-lg-12 col-12 mt-1">
                <div class="d-flex justify-content-center"> -->

                  <!-- <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Tambah Rekam Medis"
                    v-if="addMedicalRecordBtn == true"
                    @click="$router.push(`/patient/cases?patient_id=${data.item.id}&patient_name=${data.item.name} (${data.item.patient_id})&allergy=${data.item.allergy}&notes=${data.item.notes}`)"
                  ><i class="fas fa-user-md px-0"></i></b-button> -->
                  <b-button v-if="tenantConfig.subscribe_package_id != 4"
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Paket Layanan"
                    @click="
                      $router.push({
                        path: '/patient/package-paid/' + data.item.id,
                      })
                    "><i class="fas fa-shopping-basket px-0"></i></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Tambah Resep"
                    @click="
                      $router.push(
                        `/prescriptions/add?patient_id=${data.item.id}&patient_name=${data.item.name} (${data.item.patient_id})`
                      )
                    "><i class="fas fa-receipt px-0"></i></b-button>

                  <b-button
                    size="sm"
                    class="mr-1 btn-warning"
                    v-b-tooltip.hover
                    title="Cetak Blanko"
                    v-if="medicalRecordBtn == true"
                    @click="
                      $router.push({
                        path:
                          '/patient/themplate-print-patient/' + data.item.id,
                      })
                    "><i class="fas fa-print px-0"></i></b-button>
                  <b-button v-if="tenantConfig.subscribe_package_id != 4"
                    size="sm"
                    class="mr-1"
                    variant="success"
                    v-b-tooltip.hover
                    title="Tambah Sub Pasien"
                    @click="
                      showModalSubPatient(data.item.id, data.item.patient_name)
                    "><i class="fas fa-plus px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    v-if="manipulateBtn == true"
                    @click="btnDeleteOnClick(data.item.id)"><i class="fas fa-trash px-0"></i></b-button>
                </div>
              </div>
            </div>
          </template>
          <template v-if="purpose == 'modal'">
            <b-button
              size="sm"
              class="mr-1 btn-info"
              @click="
                choosePatient({
                  id: data.item.id,
                  name: data.item.name + ` (${data.item.patient_id})`,
                  allergic: data.item.allergy,
                  notes: data.item.notes,
                })
              ">Pilih</b-button>
          </template>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"></b-pagination>

      <ModalSubPatient
        :patientId="patientId"
        :patientName="patientName"
        :form="formSub"></ModalSubPatient>
    </div>
  </v-app>
</template>

<script>

import XLSX from "xlsx";
import { debouncer } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import ModalSubPatient from "@/component/patients/ModalSubPatient.vue";
import validation from "@/core/modules/ValidationModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import moment from "moment";
import { getTenantConfig } from "@/core/services/jwt.service.js";

export default {
  props: {
    purpose: String,
    selectedDoctor: String,
  },

  components: {
    ModalSubPatient,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        birt_date: "",
        start_date: moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: false,
        month: true,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "month",
      // Pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,
      recordData: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        // {
        //   key: "patient_id",
        //   label: "Nomor",
        //   sortable: true,
        // },
        {
          key: "patient_name",
          label: "Nama (Nomor)",
          sortable: true,
        },
        {
          key: "birt_date",
          label: "Tgl. Lahir & Orang Tua",
          sortable: true,
        },
        {
          key: "gender",
          label: "Jenis Kelamin",
          sortable: true,
        },
        // {
        //   key: "parent_name",
        //   label: "Orang Tua",
        //   sortable: true,
        // },
        // {
        //   key: "doctor_in_charge",
        //   label: "Dokter Yang Menangani",
        //   sortable: true,
        // },
        // {
        //   key: "responsible_doctor",
        //   label: "DPJB",
        //   sortable: true,
        // },
        // {
        //   key: "doctor",
        //   label: "Dokter",
        //   sortable: true,
        //   thClass: "th-doctor"
        // },

        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Other
      datePickerPopUp: false,
      // access Data
      manipulateBtn: false,
      paymentBtn: false,
      historyBtn: false,
      medicalRecordBtn: false,
      addMedicalRecordBtn: false,
      // current user
      currentUser: getUser(),

      // excel data
      excel: [],
      // other
      setting: {},
      patientId: "",
      patientName: "",
      tenantConfig: getTenantConfig(),

      // form sub patient
      formSub: {
        endPoint: "add-sub-patients",
        name: "",
        birt_date: "",
        notes: "",
        patient_id: this.patientId,
        patient_name: this.patientName,
      },
    };
  },

  methods: {
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.lastCurrentPage = page;

      await this.pagination();
    },

    async getSetting() {
      this.setting = await module.get(`settings/${1}`);
    },

    async pagination() {
      let route;
      if (this.currentUser.user_type_id == 1) {
        route = `patients/get-by-doctor/${this.currentUser.id}`;
      } else {
        route = "patients";
      }
      if (
        this.selectedDoctor != "" &&
        typeof this.selectedDoctor !== "undefined"
      ) {
        route = `patients/get-by-doctor/${this.selectedDoctor}`;
      }
      let filterParams = `&name=${this.filter.name}&birt_date=${this.filter.birt_date}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    async getExcel() {
      this.excel = [];

      SwalLoading.fire();

      let route = "patient/no-pagination";

      let response = await module.get(route);
      this.excel = response;

      if (response) {
        Swal.close();
      }

      if (this.excel != []) {
        const data = XLSX.utils.json_to_sheet(this.excel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, `Daftar Pasien ${this.setting.clinic_name}.xlsx`);
      }
    },

    resetFilter() {
      this.btnActiveOnClick(this.lastPeriodType);
      this.pagination();
    },

    resetFilterPeriod() {
      this.btnActiveOnClick(this.lastPeriodType);
      this.pagination();
    },

    filterByName() {
      debouncer(() => {
        this.filter.start_date = "";
        this.filter.end_date = "";
        if (this.filter.name == "") {
          this.btnActiveOnClick(this.lastPeriodType);
          this.currentPage = 1;
        } else {
          this.currentPage = 1;
        }
        this.pagination();
      }, 500)
    },

    filterByBirtDate() {
      this.pagination();
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("patients/" + id);
      // If Deleted
      if (result) {
        this.$root.$emit("UpdateNewPatient");
        this.pagination();
      }
    },

    choosePatient(value) {
      this.$emit("choosePatient", value);
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "3002") {
          this.manipulateBtn = true;
        }
        if (access_right[a] == "3003") {
          this.historyBtn = true;
        }
        if (access_right[a] == "3004") {
          this.paymentBtn = true;
        }
        if (access_right[a] == "3005") {
          this.medicalRecordBtn = true;
        }
        if (access_right[a] == "3006") {
          this.addMedicalRecordBtn = true;
        }
      }
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    showModalSubPatient(id, name) {
      this.patientId = id;
      this.patientName = name;

      console.log(this.patientId, this.patientName);
      this.$bvModal.show("add-sub");
    },
  },

  mounted() {
    // Get Data
    this.pagination();
    this.setActiveMenu();
    this.getSetting();
  },
};
</script>
<style>
.th-doctor {
  max-width: 20%;
}
</style>